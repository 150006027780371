import { Box, Button, Container, Grid, Icon, IconButton, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { IoWalletSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../constants/appRoutes';
import { StyledDescriptionText } from '../../../shared/components/Common';
import { useDialogContext, useLoaderContext, useNotifyContext } from '../../../shared/contexts';
import { AddWallet, DeleteWallet, GetWallet, UpdateWallet } from '../../../shared/helpers';
import { useErrorTranslation, useStandardCatch } from '../../../shared/hooks';

type Wallet = {
    code: number;
    userId: string;
    name: string;
    description: string;
    creationDate: string;
    amount: string;
    enabled: boolean;
    deactivationDate: string;
}

interface DetailsPanelProps {
    setWallet: React.Dispatch<React.SetStateAction<Wallet>>;
    wallet: Wallet;
}

type Errors<R> = Record<keyof R, string | undefined | null | boolean>;
type Validators<R> = Partial<Record<keyof R, { pattern: (state: R) => Partial<Errors<R>>; }>>;

export const useGeneralDetailsPanel = (props: DetailsPanelProps) => {

    const { t } = useTranslation();

    const notify = useNotifyContext();
    const { setLoader } = useLoaderContext();
    const { baseCatch } = useStandardCatch();
    const setDialog = useDialogContext();
    const navigate = useNavigate();
    const getErrorTranslation = useErrorTranslation();

    const validators = React.useRef<Validators<Wallet>>({
        name: {
            pattern: (state) => ({
                name: !state.name ? t("validations.name") : ''
            })
        },
    });

    const [walletForm, setWalletForm] = React.useState(props.wallet);
    const [walletFormErrors, setWalletFormErrors] = React.useState<Partial<Errors<Wallet>>>({
        name: '',
    });

    React.useEffect(() => {

        if (walletForm.code) {

            setLoader(true);
            GetWallet({
                params: {
                    code: walletForm.code
                }
            }).then(({ data }) => {

                setWalletForm(data.wallet as any);
                props.setWallet(data.wallet as any);
                setLoader(false);
            }).catch(ev => {
                setLoader(false);
                baseCatch(ev, '/api/wallets');
            });
        }
    }, []);

    const change = (name: keyof Wallet, value: string) => {
        const newState = { ...walletForm, [name]: value };
        setWalletForm(newState);
        const correctValidator = validators.current[name];
        if (correctValidator?.pattern) {
            const newErrors = {
                ...walletFormErrors,
                ...correctValidator.pattern(newState)
            };
            setWalletFormErrors(newErrors);
        }
    };

    const validate = (complete: () => void, fallback?: () => void) => {
        if (validators.current) {
            let _errors = { ...walletFormErrors };
            Object
                .keys(validators.current)
                .forEach((key) => {
                    const correctValidator = _.get(validators.current, key);
                    if (correctValidator?.pattern) {
                        _errors = {
                            ..._errors,
                            ...correctValidator.pattern(walletForm)
                        };
                    }
                });
            setWalletFormErrors({ ..._errors });
            const hasErrors = _.values(_errors);
            const counter = _.some(hasErrors, error => error);
            if (counter && fallback) {
                fallback();
            } else if (!counter) {
                complete();
            }
        } else {
            complete();
        }
    };

    const handleSubmitForm = () => validate(() => {
        setLoader(true);
        if (!!walletForm.code) {

            UpdateWallet({
                params: {
                    code: walletForm.code
                },
                data: walletForm
            })
                .then(() => {
                    notify.success({
                        content: t("success:updateWallet")
                    });
                    props.setWallet(walletForm);
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    baseCatch(ev, '/api/wallets');
                });
        } else {

            AddWallet({
                data: walletForm
            })
                .then(({ data }) => {
                    setWalletForm({ ...walletForm, code: data.code });
                    notify.success({
                        content: t("success:addWallet")
                    });
                    props.setWallet({ ...walletForm, code: data.code });
                    setLoader(false);
                })
                .catch(ev => {
                    setLoader(false);
                    baseCatch(ev, '/api/wallets');
                });
        }
    });

    const handleDeleteClick = React.useCallback(() => {

        setDialog({
            title: t("shared.dialogRemove_title"),
            content: t("walletsPage.dialog_RemoveWallet_body", { name: walletForm.name }),
            yesCallback: (next) => {

                setLoader(true);
                DeleteWallet({
                    params: {
                        code: walletForm.code
                    },
                    data: {
                        ...walletForm,
                        enabled: false
                    }
                })
                    .then(() => {
                        notify.success({
                            content: t("success:deleteWallet")
                        });
                        setLoader(false);
                        next();
                        navigate(`/protected/${routes.wallets}`);
                    })
                    .catch(ev => {
                        setLoader(false);
                        const status = ev.response?.data?.errorCode || ev.response?.status || 500;
                        const url = ev.config.url;
                        notify.warning({
                            content: getErrorTranslation(url, status)
                        });
                    })
            },
            noCallback: (next) => {
                next();
            }
        });
    }, []);

    return {
        models: {
            formModel: walletForm,
            formErrorModel: walletFormErrors,
        },
        events: {
            change,
            handleSubmitForm,
            handleDeleteClick,
        }
    };
};

export const GeneralDetailsPanel: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();

    const {
        models: {
            formModel,
            formErrorModel,
        },
        events: {
            change,
            handleSubmitForm,
            handleDeleteClick,
        }
    } = useGeneralDetailsPanel(props);

    return (
        <Container maxWidth="md">
            <Grid spacing={2} container pt={2}>
                <Grid md={2} item>
                    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            sx={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                border: '1px solid #ddd',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                sx={{
                                    color: "#222",
                                    fill: '#222',
                                    fontSize: '1.5rem'
                                }}
                            >
                                <IoWalletSharp />
                            </Icon>
                        </Box>
                    </Box>
                </Grid>
                <Grid md={10} item>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={12} item>
                            <StyledDescriptionText>
                                {!!formModel.code && (
                                    <IconButton
                                        onClick={async (data) => {
                                            const type = "text/plain";
                                            var blob = new Blob([String(formModel.code)], { type });
                                            await navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
                                        }}
                                    >
                                        <FaRegCopy style={{ fill: '#1c6e3d' }} />
                                    </IconButton>
                                )}
                                {t("shared.code")}: {formModel.code || 'xxxxxxxxx'}
                            </StyledDescriptionText>
                        </Grid>
                        <Grid xs={formModel.code ? 8 : 12} sm={formModel.code ? 8 : 12} md={formModel.code ? 8 : 12} item>
                            <TextField
                                label={`${t("walletDetailsPage.name")}*`}
                                name="name"
                                value={formModel.name || ''}
                                error={Boolean(formErrorModel?.name)}
                                helperText={formErrorModel.name}
                                onChange={(ev) => {
                                    const { value } = ev.target;
                                    change('name', value);
                                }}
                                InputProps={{
                                    readOnly: !formModel.enabled
                                }}
                            />
                        </Grid>
                        {!!formModel.code && (
                            <Grid xs={4} sm={4} md={4} item>
                                <TextField
                                    label={`${t("walletDetailsPage.amount")}*`}
                                    value={formModel.amount || ''}
                                    InputProps={{
                                        readOnly: true,
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        }
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} item>
                    <TextField
                        label={`${t("walletDetailsPage.description")}`}
                        name="description"
                        onChange={(ev) => {
                            const { value } = ev.target;
                            change('description', value);
                        }}
                        value={formModel.description || ''}
                        error={Boolean(formErrorModel?.description)}
                        helperText={formErrorModel.description}
                        InputProps={{
                            readOnly: !formModel.enabled
                        }}
                        multiline
                        rows={3}
                    />
                </Grid>
                {formModel.enabled && (
                    <Grid md={12} item>
                        <Stack spacing={2} direction="row" justifyContent="end" alignItems="center">
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {!!formModel.code && (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleDeleteClick}
                                    >
                                        {t("shared.delete")}
                                    </Button>
                                )}
                                <Button onClick={handleSubmitForm} >
                                    {t("shared.save")}
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
