import { Refresh } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Tooltip as MuiTooltip, Tab, Tabs, styled } from "@mui/material";
import { BarController, BarElement, CategoryScale, ChartDataset, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import zoom from 'chartjs-plugin-zoom';
import moment from 'moment';
import React from "react";
import { Chart } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { FaChartLine } from "react-icons/fa";
import { FaArrowTrendDown, FaArrowTrendUp, FaTableList } from "react-icons/fa6";
import { CTDPanelsMap } from "../..";
import { CFFDateFormat } from "../../../constants/appRegex";
import { RT, TFTable } from "../../../shared/components";
import { EmptyPage } from "../../../shared/components/Common";
import { useNotifyContext, useProfileContext } from '../../../shared/contexts';
import { GetFundOperations } from '../../../shared/helpers';
import { useErrorTranslation, useMathUtilities } from '../../../shared/hooks';
import { FundApiDto } from "../../../shared/types";

interface DetailsPanelProps {
    setFund: React.Dispatch<React.SetStateAction<FundApiDto>>;
    fund: FundApiDto;
}

ChartJS.register(
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom
);

interface FundStatisticApiDto {
    balance: number;
    lotSize: number;
    equity: number;
    operationDate: string;
    drawdown: number;
    variation: number;
}

const CondensedTab = styled(Tab)({
    minHeight: '20px',
    padding: '8px',
    minWidth: 'max-content',
    borderBottom: 'unset',
    borderRadius: '4px',
    '&.Mui-selected': {
        color: '#fff',
    }
});
const CondensedTabs = styled(Tabs)({
    minHeight: '20px',
});

type UseStatisticsChartsConsumer = (
    dataset: {
        operationDate: string;
        balance: number;
        equity: number;
        lotSize: number;
        drawdown: number;
    }[]
) => ({
    labels: string[];
    datasets: (ChartDataset<"line", number[]> | ChartDataset<"bar", number[]>)[]
});

type UseStatisticsChartsProps = (
    fund: {
        endDate: string;
        startDate: string;
        balance: number;
        expectedProfit: number;
        stopLoseTarget: number;
        takeProfitTarget: number;
    },
    reference: React.MutableRefObject<{ maxLotSize: number }>
) => UseStatisticsChartsConsumer;

const useStatisticsCharts: UseStatisticsChartsProps = (fund, reference) => {

    const { t } = useTranslation();

    const maxLimit = fund.balance + Math.round((fund.balance / 100) * fund.takeProfitTarget);

    const calculateDataset: UseStatisticsChartsConsumer = (dataset) => {

        const diff = moment(fund.endDate).diff(fund.startDate) / 1000 / 60 / 60 / 24;
        const expectedProfit = ((Math.round((fund.balance / 100) * fund.expectedProfit)) / diff);

        const statisticsCharts: {
            labels: string[];
            datasets: (ChartDataset<"line", number[]> | ChartDataset<"bar", number[]>)[]
        } = {
            labels: [],
            datasets: [
                {
                    type: 'line' as const,
                    label: t("fundDetailsPage.balance"),
                    borderColor: 'rgb(99, 132, 99)',
                    backgroundColor: 'rgba(99, 132, 99, 0.5)',
                    pointRadius: 1,
                    borderWidth: 1,
                    data: [],
                    yAxisID: 'y',
                } as ChartDataset<"line", number[]>,
                {
                    type: 'line' as const,
                    label: t("fundDetailsPage.equity"),
                    borderColor: 'rgb(255, 165, 0)',
                    backgroundColor: 'rgba(255, 165, 0, 0.5)',
                    pointRadius: 1,
                    borderWidth: 1,
                    data: [],
                    yAxisID: 'y',
                } as ChartDataset<"line", number[]>,
                {
                    type: 'line' as const,
                    borderDash: [4, 4],
                    fill: false,
                    label: t("fundDetailsPage.takeProfitTarget"),
                    borderColor: 'rgb(50, 116, 50)',
                    pointRadius: 0,
                    borderWidth: 1,
                    hidden: dataset.length < 20,
                    yAxisID: 'y',
                    data: [],
                } as ChartDataset<"line", number[]>,
                {
                    type: 'line' as const,
                    borderDash: [4, 4],
                    fill: false,
                    label: t("fundDetailsPage.stopLoseTarget"),
                    borderColor: 'rgba(255, 116, 116)',
                    pointRadius: 0,
                    borderWidth: 1,
                    hidden: dataset.length < 20,
                    yAxisID: 'y',
                    data: [],
                } as ChartDataset<"line", number[]>,
                {
                    type: 'line' as const,
                    borderDash: [4, 4],
                    fill: false,
                    label: t("fundDetailsPage.expectedProfit"),
                    borderColor: 'rgba(116, 116, 255)',
                    pointRadius: 0,
                    borderWidth: 1,
                    yAxisID: 'y',
                    data: [],
                } as ChartDataset<"line", number[]>,
                {
                    type: 'bar' as const,
                    label: t("fundDetailsPage.lotSizes"),
                    borderColor: 'rgb(132, 132, 132)',
                    backgroundColor: 'rgba(132, 132, 132, 0.4)',
                    data: [],
                    barThickness: 2,
                    position: 'right',
                    yAxisID: 'y1',
                } as ChartDataset<"bar", number[]>
            ]
        };

        const mean = (arr: number[]) => {
            const sum = arr.reduce((prev, next) => prev + next, 0);
            return Math.round(sum * 10000 / arr.length) / 10000;
        }

        dataset?.map((data, i) => {
            const date = moment.utc(data.operationDate).format(CFFDateFormat.short);
            statisticsCharts.labels?.push(date);
            statisticsCharts.datasets[0].data.push(data.balance);
            statisticsCharts.datasets[1].data.push(data.equity);
            statisticsCharts.datasets[2].data.push(maxLimit);
            statisticsCharts.datasets[3].data.push(Math.round(data.balance - ((data.balance / 100) * fund.stopLoseTarget)));
            statisticsCharts.datasets[4].data.push(fund.balance + (+expectedProfit * (i + 1)));
            statisticsCharts.datasets[5].data.push(data.lotSize);
        });

        reference.current.maxLotSize = Math.max(...statisticsCharts.datasets[5].data as number[]);

        return statisticsCharts;
    }

    return calculateDataset;
}

export const PanelStatistics: React.FC<DetailsPanelProps> = (props) => {

    const { t } = useTranslation();
    const lotSizeRef = React.useRef<{ maxLotSize: number }>({ maxLotSize: 0 });
    const { toLocalePrice } = useMathUtilities();
    const notify = useNotifyContext();
    const tableRef = React.useRef() as React.MutableRefObject<RT<FundStatisticApiDto>>;
    const { isAdmin } = useProfileContext();
    const getErrorTranslation = useErrorTranslation();

    const [panel, setPanel] = React.useState<CTDPanelsMap>(CTDPanelsMap.Chart);
    const [fundStatistics, setFundStatistics] = React.useState<FundStatisticApiDto[]>([]);
    const [statistics, setStatistics] = React.useState<{
        labels: string[];
        datasets: (ChartDataset<"line", number[]> | ChartDataset<"bar", number[]>)[]
    }>({ labels: [], datasets: [] });
    const [loader, setLoader] = React.useState(false);

    const getStatisticsChart = useStatisticsCharts(props.fund, lotSizeRef);

    const handleChange = (_: React.SyntheticEvent, newValue: CTDPanelsMap) => {
        setPanel(newValue);
    };

    const callGetFundOperation = () => {

        setLoader(true);
        tableRef.current?._table?.setLoader(true);
        GetFundOperations({
            params: {
                oid: props.fund.oid,
                balance: isAdmin ? null : 10000
            }
        }).then(({ data }) => {

            let statisticsChart = getStatisticsChart(data.fundStatistics);

            setFundStatistics(data.fundStatistics.sort((a, b) => b.operationDate.localeCompare(a.operationDate)));
            setStatistics({ ...statisticsChart });
            setLoader(false);
            tableRef.current?._table?.setLoader(false);
        }).catch((ev) => {

            setLoader(false);
            tableRef.current?._table?.setLoader(false);
            const status = ev.response?.data?.errorCode || ev.response?.status || 500;
            const url = ev.config.url;
            notify.warning({
                content: getErrorTranslation(url, status)
            });
        });
    }

    React.useEffect(() => {
        callGetFundOperation();
    }, []);

    return (
        <Box px={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <MuiTooltip title={t("shared.reload")}>
                        <IconButton onClick={callGetFundOperation}>
                            <Refresh />
                        </IconButton>
                    </MuiTooltip>
                </Box>
                <CondensedTabs value={panel} onChange={handleChange}>
                    <MuiTooltip title={t('fundDetailsPage.charts')}>
                        <CondensedTab
                            label={<FaChartLine fontSize="1.2rem" />}
                            value={CTDPanelsMap.Chart}
                        />
                    </MuiTooltip>
                    <MuiTooltip title={t('fundDetailsPage.tables')}>
                        <CondensedTab
                            label={<FaTableList fontSize="1.2rem" />}
                            value={CTDPanelsMap.Table}
                        />
                    </MuiTooltip>
                </CondensedTabs>
            </Box>
            <Box
                sx={{
                    boxSizing: 'border-box',
                    overflow: 'auto',
                    width: 'calc(100vw - 270px)'
                }}
            >
                {panel === CTDPanelsMap.Chart && (
                    <Box>

                        {loader && (
                            <Box height="calc(100vh - 270px)" width="100%" display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        )}


                        {!loader ? statistics?.labels?.length ? (
                            <Box sx={{ overflow: 'auto', textAlign: 'center', alignItems: 'center' }}>
                                <Box sx={{ height: 'calc(100vh - 183px)' }}>
                                    <Chart
                                        type='bar'
                                        data={statistics}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    offset: false,
                                                    grid: {
                                                        drawOnChartArea: true,
                                                        drawTicks: true
                                                    },
                                                },
                                                y: {
                                                    type: 'linear',
                                                    display: true,
                                                    position: 'left',
                                                },
                                                y1: {
                                                    type: 'linear',
                                                    display: true,
                                                    position: 'right',
                                                    max: lotSizeRef.current.maxLotSize * 8,
                                                    grid: {
                                                        drawOnChartArea: true
                                                    },
                                                },
                                            },
                                            plugins: {
                                                zoom: {
                                                    zoom: {
                                                        wheel: {
                                                            enabled: true
                                                        },
                                                        drag: {
                                                            enabled: true
                                                        },
                                                        mode: "x",
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ height: 'calc(100vh - 216px)' }}>
                                    <EmptyPage title={t("fundDetailsPage.noOperations")} />
                                </Box>
                        ) : null}
                    </Box>
                )}

                {panel === CTDPanelsMap.Table && (
                    <Box sx={{ width: 'calc(100vw - 280px)' }}>
                        <TFTable<FundStatisticApiDto>
                            ref={tableRef}
                            data={fundStatistics}
                            options={{
                                usePagination: false,
                                useSearch: false,
                                localize: {
                                    noData: t('shared.table.gridEmptyMsg'),
                                    resetFilters: t('shared.table.resetFilters'),
                                    search: t('shared.table.search'),
                                    displayedElementsSeparator: t('shared.table.displayedElementsSeparator'),
                                    rowsForPage: t('shared.table.rowsForPage'),
                                },
                                minWidth: "1000px",
                                style: {
                                    maxHeight: "calc(100vh - 217px)"
                                },
                                filtersAdjustmentStyle: {
                                    top: '40px'
                                }
                            }}
                            columns={[
                                {
                                    title: () => t("fundDetailsPage.date"),
                                    render: ({ operationDate }) => moment.utc(operationDate).format(CFFDateFormat.short),
                                    thStyle: { textAlign: 'right', width: '110px' },
                                    style: { textAlign: 'right' }
                                },
                                {
                                    title: () => t("fundDetailsPage.balance"),
                                    render: ({ variation, balance }, _f, _c) => {
                                        const goodBalance = variation >= 0;
                                        return (
                                            <Box sx={{ color: goodBalance ? '#3bb73b' : '#ff7474', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'end' }}>
                                                <b>{toLocalePrice(balance)}</b>
                                                {!goodBalance ?
                                                    <FaArrowTrendDown style={{ color: '#ff7474' }} /> :
                                                    <FaArrowTrendUp style={{ color: '#3bb73b' }} />}</Box>
                                        );
                                    },
                                    thStyle: { textAlign: 'right' },
                                    style: { textAlign: 'right' }
                                },
                                {
                                    title: () => t("fundDetailsPage.lotSize"),
                                    render: ({ lotSize }) => toLocalePrice(lotSize, 2, false),
                                    thStyle: { textAlign: 'right', width: '80px' },
                                    style: { textAlign: 'right' }
                                },
                                {
                                    title: () => t("fundDetailsPage.drawdown"),
                                    render: ({ drawdown, balance }) => {
                                        return <Box><b>{toLocalePrice(drawdown)}</b> <small>({toLocalePrice((drawdown * 100) / balance)}%)</small></Box>;
                                    },
                                    thStyle: { textAlign: 'right' },
                                    style: { textAlign: 'right' }
                                },
                                {
                                    title: () => `${t("fundDetailsPage.variation")}`,
                                    render: ({ variation, balance }) => {
                                        return <Box><b>{toLocalePrice(variation)}</b> <small>({toLocalePrice((variation * 100) / balance)}%)</small></Box>;
                                    },
                                    thStyle: { textAlign: 'right' },
                                    style: { textAlign: 'right' }
                                },
                                {
                                    title: () => t("fundDetailsPage.progressive"),
                                    render: ({ balance }) => {
                                        const variation = balance - props.fund.balance;
                                        return <Box><b>{toLocalePrice(variation)}</b> <small>({toLocalePrice(((variation) * 100) / props.fund.balance)}%)</small></Box>;
                                    },
                                    thStyle: { textAlign: 'right' },
                                    style: { textAlign: 'right' }
                                },
                            ]}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}
